/*global chrome*/

import {
  Avatar,
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Button,
  Progress,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton
} from "@chakra-ui/react";
import AccountTagCloseButton from "components/accounttag";
import { logoutFirebase, auth, functions } from "firebase.js";
import useCenterLayout from "hooks/useCenterLayout";
import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaEnvelope, FaBroom, FaPaperPlane, FaUser, FaInfoCircle, FaLockOpen, FaPen, FaPenSquare, FaPiggyBank, FaPlus, FaQuestion, FaSearch, FaSignOutAlt, FaSortDown, FaStar, FaTint } from "react-icons/fa";
import { brandcolors, dashboardcolors } from "theme/brandcolors";
import { useHistory } from "react-router-dom";
import { Email, FirebaseBackendFunctions } from "axios.js";
import Inbox from "components/inbox";
import { useQuery, useQueryClient } from "react-query";
import * as reactquerystrings from 'reactquery.js'
import axios from "axios";
import LoadingSpinner from "components/loading/spinner";
import { useEmailStore } from 'stores/emailStore'
import Signupstab from "./signupstab";
import { free_emailaccounts } from "usersettings";
import Loading2Lottie from "components/lotties/loading2";
import SearchVault from "components/search";
import AccountDetails from "components/accountdetails";
import * as ROUTES from 'routes.js'

const extId = process.env.REACT_APP_EXTID //jlhjlhemgdoanpihhdgeaaijogifmldi


const DashboardPage = () => {
  const [user, loading] = useAuthState(auth);
  var history = useHistory()

  useEffect(() => {
    if(loading){
      // console.log('loading user information...', user)
      return
    }
    if(user){
      // console.log(user)
      //request the server for a custom token with the user's uid and pass it to the chrome extension
      // var token = functions().httpsCallable('createToken')(user.uid) 
      if(user.emailVerified === false){
        history.replace(ROUTES.VERIFICATIONPAGE)
      }
      FirebaseBackendFunctions.createtoken(user.uid)
        .then(res =>{
          //user logged in, so also log the user in the chrome extension
          chrome.runtime.sendMessage(extId, {token: res.data.token}, resFromExtension =>{
            // if(!resFromExtension.success){
            //   console.log(resFromExtension)
            // }
          })
        })
        .catch(err =>{
          console.log(err)
        })
    }
    else{
      console.log('logged out')
      history.replace('/signup-login')
    }
  }, [user, loading, history])

  return (
    loading ? 
      <Text>Loading...</Text>
    :
      user && <Box>
        {/* Top Nav */}
        <Box w="100%" backgroundColor={dashboardcolors.topnav.base}>
          <Center w="100%">
            <HStack
              align="center"
              alignItems="stretch"
              p={3}
              spacing={8}
              justify="start"
            >
              <Heading color="white">Crumbly</Heading>
              <SearchVault></SearchVault>
              <HStack spacing={4}>
                <Avatar name={user?.displayName || "Larry Smith"} />
                <VStack spacing={1} align="start">
                  <Text fontSize="sm" color="white">
                    {user.email}
                  </Text>
                  <Text fontSize="xs" color="white">
                    Regular User
                  </Text>
                  
                </VStack>
                    {/* dropdown options */}
                <Menu>
                  <MenuButton as={Button} colorScheme="gray" variant ="ghost" color="white" _hover={{color: "#000", bg: 'white'}} _focus={{ boxShadow: "none", }} >
                  <FaSortDown />
                  </MenuButton>
                  <MenuList>
                    <MenuItem  onClick={(e) => logoutFirebase()} ><Text fontWeight="semibold">Logout</Text></MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>
          </Center>
        </Box>
      
        {/* Tabs */}
        <Grid
          h="100px"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap={4}
        >
          <GridItem colSpan={3}>
            <TabsDashboard>
              <EmailAccountsTab />
              <Signupstab />
            </TabsDashboard>
          </GridItem>
        </Grid>
      </Box>
  );
};

const EmailAccountsTab = () => {
  const queryClient = useQueryClient()
  var [centerWidth, centerHeight] = useCenterLayout();
  const [user, loading, error] = useAuthState(auth);
  var searchemail = user !== null || user !== undefined ? user?.email : '' 
  const currentEmailAccount = useEmailStore(state => state.currentEmailAccount)
  // searchemail = currentEmailAccount.toString()
  console.log(`searcHEMAIL : ${currentEmailAccount}`)
  // const [currentEmailAccount, setCurrentEmailAccount] = useState('')
  // const currentEmailAccount = useEmailStore(state => state.currentEmailAccount)

  const setCurrentEmailAccount = useEmailStore(state => state.setCurrentEmailAccount)
  const setCurrentTags = useEmailStore(state => state.setCurrentTags)
  const setAccountDescription = useEmailStore(state => state.setaccountdescription)

  const [showInbox, setshowInbox] = useState(true)

  var [mainTagColor, setMainTagColor] = useState("blue");

  const emailButtonsRefs = useRef([])
  var [currentEmailButton, setCurrentEmailButton] = useState(null)
  const {data: emaildata, isLoading: isLoadingEmails, isFetching, isError, refetch} = useQuery([reactquerystrings.ALLEMAILSGET, searchemail], () => Email.getAllEmailAccountsForUser(searchemail))
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal , onClose: onCloseDeleteModal } = useDisclosure()
  const { isOpen: isOpenInfoModal, onOpen: onOpenInfoModal , onClose: onCloseInfoModal } = useDisclosure()
  const { isOpen: isOpenCredentialsModal, onOpen: onOpenCredentialsModal , onClose: onCloseCredentialsModal } = useDisclosure()


  useEffect(() => {
    if(emaildata?.message?.length > 0){
      setCurrentEmailAccount(emaildata.message[0].email)
      setCurrentTags(emaildata.message[0].tags)
      setCurrentEmailButton(0)
      setAccountDescription(emaildata.message[0].accountdescription)
      
    }
  
  }, [emaildata, setCurrentEmailAccount, setCurrentTags])
  useEffect(() => {
    
    
    refetch().then((data) =>{
      console.log(data)
      emailButtonsRefs.current.forEach((buttonRef, index) => {
        if(index !== currentEmailButton)
          buttonRef.style.backgroundColor = "#FFF"
        else{
          buttonRef.style.backgroundColor = "#EDF2F7"
        }
      })
    })
  
  }, [currentEmailButton])
  

  if(isLoadingEmails || isFetching){
    return (
      // <LoadingSpinner></LoadingSpinner>
      <Loading2Lottie width={50} height={50}></Loading2Lottie>
    )
  }
  
  console.log(emaildata);

  const loginToChromeExtension = () =>{
    FirebaseBackendFunctions.createtoken(user.uid)
        .then(res =>{
          //user logged in, so also log the user in the chrome extension
          chrome.runtime.sendMessage(extId, {token: res.data.token}, resFromExtension =>{
            // if(!resFromExtension.success){
            //   console.log(resFromExtension)
            // }
          })
        })
        .catch(err =>{
          console.log(err)
        })
  }
  
  const changeEmailAccount = (e, account, index, tags, accountdescription) => {
    // setCurrentEmailAccount(old => account)
    setCurrentEmailAccount(account)
    setCurrentEmailButton(index) // keep track of the index of the current button for useffect to properly set button active
    setCurrentTags(tags)
    setAccountDescription(accountdescription)
    // e.currentTarget.style.backgroundColor = "#EDF2F7"
    console.log('switching account to ', currentEmailAccount)
    // console.log(tags);
    // console.log(accountdescription);

  }

  var tempArrayOfEmailAccounts = [
    "jason_tree33@getcrumbly.com",
    "jeff_aquatic353@getcrumbly.com",
    "bree_se|fle33232@getcrumbly.com",
    "alex_coffee565@getcrumbly.com",
  ];
  tempArrayOfEmailAccounts = emaildata.message ? emaildata.message.map(emailAccount =>{
    return emailAccount.email
  }) : []

  const EmailAccountComponents = tempArrayOfEmailAccounts.map((account, index) => {
    return (
      <Button _focus={{ boxShadow: "none", }} onClick={(e) =>  changeEmailAccount(e, account, index, emaildata.message[index] !== undefined ? emaildata.message[index].tags : [], emaildata.message[index] !== undefined ? emaildata.message[index].accountdescription : '') } ref={el => emailButtonsRefs.current[index] = el} key={account} variant="ghost" colorScheme="gray" color="teal">
        {account}
      </Button>
    );
  });
  return (
    <Center w={centerWidth} h={centerHeight / 2}>
      <Modal closeOnOverlayClick={false} isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to delete this account and emails?</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <p>This action cannot be reversed even with tech support. It's PERMANENT!</p>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() =>{
               Email.deleteEmailAccountAndEmails(currentEmailAccount)
               .then(res =>{
                 console.log(res.message)
                 if(tempArrayOfEmailAccounts.length > 0){
                   const index = tempArrayOfEmailAccounts.indexOf(currentEmailAccount)
                   tempArrayOfEmailAccounts.splice(index, 1)
                   if(tempArrayOfEmailAccounts.length === 0){
                     setCurrentEmailAccount('')
                   } else{
                     setCurrentEmailAccount(tempArrayOfEmailAccounts[0])
                   }
                   onCloseDeleteModal()

                 }
               })
            }}>
              Confirm
            </Button>
            <Button onClick={onCloseDeleteModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack spacing={4} bg={dashboardcolors.tabs.background} w="100%" h="100%" p={8}>
        <HStack spacing={4} >
        {/*Accounts Overview Card */}
            <VStack spacing={2}  >
              <CardHeading text="Accounts Overview" />
              <HStack bg="white" boxShadow="md" rounded="lg" p={8}>
                <VStack mr={6}>{EmailAccountComponents.length > 0 ? EmailAccountComponents : <Text>No Accounts</Text>  }</VStack>
                <VStack spacing={3}>
                  <Progress
                    value={Math.floor((EmailAccountComponents.length / free_emailaccounts) * 100)}
                    min="0"
                    max="100"
                    size="lg"
                    w="240px"
                    colorScheme="blue"
                    rounded="lg"
                  />
                  <HStack>
                  <Text fontSize="2xl" fontWeight="semibold">
                    {`${EmailAccountComponents.length} / ${free_emailaccounts} Accounts`}
                  </Text>
                  <IconButton aria-label='Account Info Button' icon={<FaQuestion />} onClick={onOpenInfoModal} />
                  </HStack>
                  <Modal isOpen={isOpenInfoModal} onClose={onCloseInfoModal}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Notice</ModalHeader>
                      <ModalBody>
                        <p>Crumbly is currently in beta, so we're testing number of accounts set to five. Crumbly is <b>free</b> and may offer unlimited accounts in the future. For now, we appreciate your patience.</p>
                      </ModalBody>

                      <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onCloseInfoModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  {/* <Button
                    justifySelf="end"
                    rightIcon={<FaStar />}
                    bg="gray.400"
                    color="white"
                    _focus={{ boxShadow: "none", }}
                  >
                    Upgrade
                  </Button> */}
                </VStack>
              </HStack>
            </VStack>


        {/* Email Inbox Render */}
            <VStack>
              {showInbox && <CardHeading text="Inbox" />}
              {showInbox && <Inbox emailAccount={currentEmailAccount || tempArrayOfEmailAccounts[0]}/>}
            </VStack>
        </HStack>
        {/*More Information Cards  */}
        <HStack spacing={20} >
          <VStack >
              {/* Details Card */}
            <CardHeading text="Details" />
            <AccountDetails></AccountDetails>
          </VStack>
          <VStack>


              {/* Actions Card */}
            <CardHeading text="Actions" />
            <Box bg="white" boxShadow="md" rounded="lg" p={5} >
            <VStack align="start" divider={<Divider />}>
                    <Modal closeOnOverlayClick={false} isOpen={isOpenCredentialsModal} onClose={onCloseCredentialsModal} size='lg'>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{currentEmailAccount !== '' ? 'Copy Username to Clipboard' : ''}</ModalHeader>
                        <ModalBody pb={6}>
                          <HStack  >
                              {currentEmailAccount !== '' ?
                              <VStack spacing={4}>
                                <HStack>
                                  <FaPenSquare size="24px" />
                                  <Button onClick={(e)=>{
                                      let x = document.getElementById('currentAccountUsername').innerHTML
                                      navigator.clipboard.writeText(x) 
                                  }} _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray">Username: <Text color="yellow.700" mx={1} id='currentAccountUsername'> {currentEmailAccount }</Text> </Button>
                                </HStack>
                                <HStack>
                                  <FaInfoCircle alignSelf="start" size="24px"/>
                                  <Text fontWeight="bold">Password Notice</Text>
                                </HStack>
                                <Text> Every product or website you sign up to is automatically remembered for that site, so this account will have multiple passwords that you don't have to remember.</Text>

                              </VStack>
                                
                                :
                                <Box><Text>No Account Selected</Text></Box>
                              }
                                
                            
                          </HStack>
                        </ModalBody>

                        <ModalFooter>
                          <Button onClick={onCloseCredentialsModal}>Cancel</Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>

                    <HStack  >
                        <FaPaperPlane size="24px" />
                        <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray" > <a href="https://www.youtube.com/watch?v=EkED2llLV1Y" target="_blank" rel="noreferrer"><Text color={'gray'} mx={1}> Watch tutorial video</Text></a> </Button>
                    </HStack>
                    <HStack>
                        <FaEnvelope size="24px" />
                        <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray" onClick={() => setshowInbox(!showInbox)}>{showInbox ? 'Hide' : 'Show'}  <Text color="gray.400" ml={1} > Mailbox</Text></Button>
                    </HStack>
                    <HStack  >
                        <FaSignOutAlt size="24px" />
                        <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray" onClick={loginToChromeExtension}><Text color={brandcolors.success.normal} mx={1}> Login</Text> to Chrome Extension</Button>
                    </HStack>
                    <HStack  >
                        <FaUser size="24px" />
                        <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray" onClick={onOpenCredentialsModal}><Text color={brandcolors[200]} mx={1}> View Login Credentials</Text> of this Account</Button>
                    </HStack>
                    <HStack  >
                        <FaLockOpen size="24px" />
                        <Button onClick={(e)=>{
                              if(currentEmailAccount !== '')
                                onOpenDeleteModal()

                         
                        }} _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray">Delete this <Text color="yellow.700" mx={1}> Account</Text> and its <Text color="yellow.700" mx={1}>Emails</Text> </Button>
                    </HStack>
                    <HStack  >
                        <FaBroom size="24px" />
                        <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray"><a href="https://crumbly-store.printify.me/products" target="_blank" rel="noreferrer"><Text color={brandcolors[700]} mx={1}> Buy Crumbly Merch!</Text> </a></Button>
                    </HStack>
                    <HStack  >
                        <FaPaperPlane size="24px" />
                        <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray" > <a href="https://docs.google.com/forms/d/e/1FAIpQLSfKKTzKOAiqgGXvBXu7pWJUj1jX7VzGvqa8uyRfjPD_zkP6HQ/viewform?embedded=true" target="_blank" rel="noreferrer"><Text color={brandcolors[700]} mx={1}> Give feedback or submit a bug</Text> </a> </Button>
                    </HStack>
                </VStack>
            </Box>
          </VStack>
          {/* <VStack>
            <CardHeading text="this" />
            <Box bg="white" boxShadow="md" rounded="lg" >
              <Heading>placeholder</Heading>
              <VStack align="start" divider={<Divider />}>
                <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray" onClick={() => logoutFirebase()}>Logout</Button>    
                <Button _focus={{ boxShadow: "none", }} variant="ghost" colorScheme="gray" onClick={loginToChromeExtension}>Login to Chrome Extension</Button>
              </VStack>
            </Box>
          </VStack> */}
        </HStack>
        <Box mt='2' h='150px' w='100%'>Crumbly is free to use. Please consider spreading the word on Reddit and your favorite platforms.</Box>

      </VStack>

    </Center>
  );
};

// const SignupsTab = () => {
//   var [centerWidth, centerHeight] = useCenterLayout();
//   const [user, loading, error] = useAuthState(auth);
//   const currentEmailAccount = useEmailStore(state => state.currentEmailAccount)
//   const setCurrentEmailAccount = useEmailStore(state => state.setCurrentEmailAccount)
//   // const [currentEmailAccount, setCurrentEmailAccount] = useState('')
//   var [mainTagColor, setMainTagColor] = useState("blue");
  
//   if(false){ //change this later
//     return (
//       <LoadingSpinner></LoadingSpinner>
//     )
//   }
  

//   return (
//     <Center w={centerWidth} h={centerHeight / 2}>
//       <VStack spacing={4} bg={dashboardcolors.tabs.background} w="100%" h="100%" p={8}>
//         <Text>HELLO! {currentEmailAccount}</Text>
//       </VStack>
//     </Center>
//   );
// };


const TabsDashboard = (props) => {
  var children = React.Children.toArray(props.children);
  return (
    <Tabs
      variant="unstyled"
      align="center"
      colorScheme="teal"
      size="lg"
      marginTop={3}
      mx={0}
    >
      <TabList>
        <Tab
          _selected={{ borderBottom: "1px solid teal" }}
          _focus={{ boxShadow: "none", fontWeight: "semibold" }}
        >
          Email Accounts
        </Tab>
        <Tab
          _selected={{ borderBottom: "1px solid teal" }}
          _focus={{ boxShadow: "none", fontWeight: "semibold" }}
        >
          Signups
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel px={0} w="100%" h="100%">{children[0]}</TabPanel>
        <TabPanel px={0} w="100%" h="100%">{children[1]}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

/* Components */

export const CardHeading = (props) => {
  return (
    //   must be part of a VStack
    <Heading alignSelf="start" size="md" color="gray.500">
      {props.text}
    </Heading>
  );
};

export default DashboardPage;
