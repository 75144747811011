import { Avatar, Box, Badge, Button, Divider, Heading, HStack, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { brandcolors } from 'theme/brandcolors'
import {FaUndo, FaRegSquare, FaTrashAlt, FaRegFileImage, FaArrowCircleLeft, } from 'react-icons/fa'
import { useQuery } from 'react-query'
import * as reactquerystrings from 'reactquery.js'
import { Email } from 'axios.js'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from "firebase.js";
import LoadingSpinner from './loading/spinner'
import EmptyBoxLottie from './lotties/emptyinbox'
import Loading2Lottie from './lotties/loading2'

export const EmailTableRow = (props) => {

    const subjectlimit = 50
    
    const attachmentlimit = 2
    const attachments = props.attachments.map((attachment, index) => {
        if(index >= attachmentlimit) return '...'
        let extension =  attachment.filename.substring(attachment.filename.lastIndexOf('.'), attachment.filename.length) 
        let attachment_trunc = attachment.filename.length > 16 ? attachment.filename.substring(0, 16) + '...' + extension : attachment.filename
        return <Text key={index}>{attachment_trunc}</Text>
    })
    return (
      <Tr fontSize="sm" onClick={props.renderEmail} style={{cursor: "pointer"}} _hover={{bg: brandcolors[400]}}>
          <Td><VStack ><Text fontWeight="bold">{props.sender.split('<')[0]}</Text><Text>{props.sender.split('<')[1] !== undefined ? props.sender.split('<')[1].replace('>', '') : ''}</Text><Badge variant='outline' >{new Date(props.date).toString().substring(0, props.date.lastIndexOf(':') - 1)}</Badge></VStack></Td>
          <Td>{props.subject}</Td>
          <Td>{attachments}</Td>
  
      </Tr>
    )
}


const EmailView = (props) => {
  let emailinfo = props.email
  let name = emailinfo.sender.split('<')[0]
  let emailaddress = emailinfo.sender.split('<')[1] !== undefined ? emailinfo.sender.split('<')[1].replace('>', '') : ''
  let date = new Date(emailinfo.date).toString()
  date = date.substring(0, date.lastIndexOf(':') + 3)

  let attachments = emailinfo.attachments
  let subject = emailinfo.subject

  console.log(emailinfo)
  let emailrenderview = React.useRef()
  useEffect(() => {
    emailrenderview.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'})
  }, [])


  const downloadAll = (attachment) =>{
    attachments.map(attachment => {
        download(attachment.content.data, attachment.filename, attachment.contentType)
    })
  }

  function download(content, filename, contentType){

    const data = Uint8Array.from(content);

        if(!contentType) contentType = 'application/octet-stream';
            var a = document.createElement('a');
            var blob = new Blob([data.buffer], {type: contentType});
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.click();
    }

  const getSizeAttachment = (size) =>{
      if(size < 1000){
          return '< 1KB'
      }
      else if(size >= 1000 && size <= 1000000){
          return `${Math.floor(size / 1000)}KB`
      }
      else if(size >= 1000000 && size <= 1000000000){
        return `${Math.floor(size / 1000000)}MB`
    }

  } 

  const AttachmentsMenu = () => {
    const MenuItems = attachments.map((attachment, index) =>{

        return (
            <MenuItem key={`attachmentmenu${index}`} onClick={(e) => download(attachment.content.data, attachment.filename, attachment.contentType)} >{attachment.filename} <Text mx={2}>   | </Text> <Text fontSize="sm" fontWeight="semibold">  ({getSizeAttachment(attachment.size)})</Text></MenuItem>
        )
    })
    MenuItems.push(<MenuItem onClick={downloadAll} disabled={attachments.length === 0} key='attachmentmenulast' fontWeight="bold">Download All</MenuItem>)
    return (
        <Menu>
            <MenuButton as={Button} colorScheme="gray" variant ="ghost" color="white" _hover={{color: "#000", bg: 'white'}} rightIcon={<FaUndo />}>
                Attachments
            </MenuButton>
            <MenuList>
                {MenuItems}
            </MenuList>
        </Menu>
    )
  }
    
  return (
    <Box>
        {/* Backdrop with options */}
        <HStack ref={emailrenderview} bg="#000" rounded="md" justify="space-between">
            <Button onClick={props.handlerFunctions.goBackInbox} leftIcon={<FaArrowCircleLeft />} colorScheme='teal' variant='solid' _hover={{bg: brandcolors[400], color: "#000"}}>Back</Button>
            <HStack alignSelf="flex-end" spacing={8}>
                {/* <Button colorScheme="gray" variant ="ghost" color="white" _hover={{color: "#000", bg: 'white'}}>Attachments</Button> */}
                <AttachmentsMenu />
               {/* Uncomment out this button if doing demail delete feature*/} {/* <Button colorScheme="gray" variant ="ghost" color="white"  _hover={{color: "#fff", bg: '#B83E13'}}>Delete</Button> */}
            </HStack>
        </HStack>
        {/* Email info */}
        <HStack justify="space-between" alignItems="space-between" spacing={8} p={4}>
            <HStack>
                <Avatar size="md" name={name}></Avatar>
                <VStack  alignItems="start">
                    <Text fontWeight="bold">{name}</Text>
                    <Text>{emailaddress}</Text>
                </VStack>
            </HStack>
            <VStack  marginLeft={100}>
                <Text alignSelf="end">Date</Text>
                <Text>{date}</Text>
            </VStack>
        </HStack>
        <Divider></Divider>
        <HStack spacing={8} p={4}>
            <Text color={brandcolors[100]}>Subject:</Text>
            <Text >{subject}</Text>
        </HStack>
        <Divider></Divider>

        {/* Email Render */}
        <iframe srcDoc={emailinfo.email} frameBorder="0" width="800px" height="600px"></iframe>
    </Box>
  )
}


const Inbox = (props) => {
    var numEmailsPerPage = 5
    var nextDisabled = true

    const [user, loading, error] = useAuthState(auth);
    const [renderView, setRenderView] = useState('inbox') // inbox: email list view , email: specific email render
    const [selectedEmail, setSelectedEmail] = useState(null) // inbox: email list view , email: specific email render
    const [currentPage, setCurrentPage] = useState(0) 
    const {data: emaildata, isLoading: isLoadingEmails, isError, isFetching, isPreviousData, refetch} = useQuery([reactquerystrings.GETEMAILSFORACCOUNT, props.emailAccount, currentPage], () => Email.getEmailsForAccount(props.emailAccount, currentPage, numEmailsPerPage), {keepPreviousData: true})

    // console.log(emaildata)

    if(isLoadingEmails || isFetching){
        return (
            // <LoadingSpinner></LoadingSpinner>
            <Loading2Lottie width={250} height={250}></Loading2Lottie>
        )
    }
    if(isError){
        return (
            <Box bg="white" boxShadow="md" rounded="lg" p={8} maxH="600px" maxW="800px">
                
                <Box overflowX="auto" w='100%' >
                    <Table variant='striped' colorScheme='gray'   >
                        <Thead>
                            <Tr bg="black"  >
                                <Th color="white"  >SENDER</Th>
                                <Th color="white" >SUBJECT</Th>
                                <Th color="white" >ATTACHMENTS</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Text >No emails!</Text>
                        </Tbody>
                    </Table>
                    <EmptyBoxLottie />
                </Box>
            </Box>
        )
    }
 
    const renderEmail = (e, email) =>{

        setRenderView('email')
        setSelectedEmail(() => email)
    }

    const loadPrevPage = (e) => { 
        setCurrentPage(old => Math.max(old - 1, 0))
    }
    const loadNextPage = (e) => { 
        if( !isPreviousData && !nextDisabled){
            setCurrentPage(old => old + 1)
        }
    }

    const goBackInbox = (e) => {
        setRenderView('inbox')
    }

    console.log(emaildata.emails)
    if(emaildata.emails === undefined || emaildata.emails.length === 0){
        return (
            <Box bg="white" boxShadow="md" rounded="lg" p={6} maxH="600px" maxW="800px">
                <HStack alignSelf="end"  spacing={1} bg='gray' opacity={.4} maxWidth={1/3}>
                        <Text color="black">Refresh</Text>
                        <Button colorScheme='gray' variant='ghost' onClick={refetch} size='sm'>
                            <FaUndo  />
                        </Button>
                       
                    </HStack>
                <Box overflowX="auto" w='100%' >
                    <Table variant='striped' colorScheme='gray'   >
                        <Thead>
                            <Tr bg="black"  >
                                <Th color="white"  >SENDER</Th>
                                <Th color="white" >SUBJECT</Th>
                                <Th color="white" >ATTACHMENTS</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Text >No emails!</Text>
                        </Tbody>
                    </Table>
                    <EmptyBoxLottie />
                 
                </Box>
            </Box>
        )
    }
    var emailsRender = emaildata.emails.sort((a, b) =>{
        var adate = new Date(a.date); 
        var bdate = new Date(b.date); 

        var amilliseconds = adate.getTime(); 
        var bmilliseconds = bdate.getTime(); 

        return bmilliseconds - amilliseconds
    }).map((email, index) =>{        
        if(email.hasMore)
            nextDisabled = false
        return (
            <EmailTableRow renderEmail={(e) => renderEmail(e, email)} key={email.subject + index.toString()} sender={email.sender} subject={email.subject} attachments={email.attachments} date={email.date}></EmailTableRow>

        )
    })
  return (
    <VStack bg="white" boxShadow="md" rounded="lg" p={0}  maxW="800px" >
        {
            renderView === 'email' ? 
                <EmailView email={selectedEmail} handlerFunctions={{goBackInbox: goBackInbox}} />
            :
            (
                <Box>
                    <HStack alignSelf="start"  spacing={8}>
                        <Button colorScheme='gray' variant='ghost' onClick={refetch} >
                            <FaUndo  />
                        </Button>
                        {/* <FaRegSquare />
                        <FaTrashAlt /> */}
                    </HStack>
                    <Box overflowX="auto" w='100%' >
                        <Table variant='simple' colorScheme='gray'   >
                            <Thead>
                                <Tr bg="black"  >
                                    <Th color="white"  >SENDER</Th>
                                    <Th color="white" >SUBJECT</Th>
                                    <Th color="white" >ATTACHMENTS</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {emailsRender}
                        
                            </Tbody>
                        </Table>
                    </Box>
                    <HStack>
                        <Button onClick={loadPrevPage} disabled={currentPage === 0} colorScheme="teal" variant="ghost" >Prev</Button>
                        <Button onClick={loadNextPage} disabled={isPreviousData || nextDisabled} colorScheme="teal" variant="ghost" >Next</Button>
                    </HStack>
                </Box>
            )
        }
        
    
       
    </VStack>
  )
}






export default Inbox